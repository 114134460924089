<template>
  <div class="">
    <div v-if="isLoaded" class="mt-3">
      [ STATUS ]

      <div v-if="errorMessage">
        {{errorMessage}}
      </div>

      <ul class="list-unstyled" v-else>
        <li>Ip: {{status.ip}}</li>
        <li>Port: {{status.port}}</li>
        <li>
          Gloabal:
          <ol>
            <li
              v-for="(tag,idx) in status.globalAddresses"
              :key="idx"
              class="mb-3">
              <div v-if="tag.unitId" class="text-info">Unit ID: {{tag.unitId}}</div>
              <div v-if="tag.addr" class="text-info">Addr: {{tag.addr}}</div>
              <div v-if="tag.len" class="text-warning">Length: {{tag.len}}</div>
            </li>
          </ol>
        </li>
        <li>
          Read:
          <ol>
            <li
              v-for="(tag,idx) in status.readAddresses"
              :key="idx"
              class="mb-3">
              <div v-if="tag.unitId" class="text-info">Unit ID: {{tag.unitId}}</div>
              <div v-if="tag.name" class="text-success">Name: {{tag.name}}</div>
              <div v-if="tag.addr" class="text-info">Addr: {{tag.addr}}</div>
              <div v-if="tag.scale" class="text-warning">Scale: {{tag.scale}}</div>
            </li>
          </ol>
        </li>
        <li>
          Write:
          <ol>
            <li
              v-for="(tag,idx) in status.writeAddresses"
              :key="idx"
              class="mb-3">
              <div v-if="tag.unitId" class="text-info">Unit ID: {{tag.unitId}}</div>
              <div v-if="tag.name" class="text-success">Name: {{tag.name}}</div>
              <div v-if="tag.addr" class="text-info">Addr: {{tag.addr}}</div>
              <div v-if="tag.scale" class="text-warning">Scale: {{tag.scale}}</div>
            </li>
          </ol>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { DETAIL_STATUS } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isLoaded: false,
      errorMessage: '',
      status: {
        ip: '',
        port: 0,
        globalAddresses: [],
        readAddresses: [],
        writeAddresses: []
      },
      message: ''
    }
  },
  methods: {
    fetchData () {
      this.isLoaded = false
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        Object.keys(this.status).forEach(key => {
          this.status[key] = status[key]
        })
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
